import Toastify from "toastify-js";

window.showToast = function showToast(text, type) {
    Toastify({
        text: text,
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: "bottom",
        position: "right",
        className: type,
        stopOnFocus: true,
    }).showToast();
};
