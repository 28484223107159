import scroll from "./scroll.js";

const scrollUp = document.getElementById("js-scrollUp");

scrollUp.addEventListener("click", function () {
    scroll.animateScroll(0);
});

window.addEventListener("scroll", function () {
    scrollUp.classList.toggle(
        "active",
        window.scrollY > 100 && window.oldScroll > window.scrollY
    );
    window.oldScroll = window.scrollY;
});
